import React, { useState, useRef, useCallback } from 'react';
import { SearchState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, Toolbar, TableColumnResizing, SearchPanel, VirtualTable, ExportPanel } from '@devexpress/dx-react-grid-bootstrap4';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import { useSelector } from 'react-redux';
import clienteAxios from '../../../config/axios';
import { navigate } from 'gatsby-link';
import saveAs from 'file-saver';

const getRowId = row => row._id;
const Clients = () => {
    const token = useSelector(state => state.auth.token)
    const [data, setData] = useState([])

    const exporterRef = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    React.useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get('/api/space', config)
            .then((res) => {
                // console.log(res.data)
                let users = []
                for (let i = 0; i < res.data.length; i++) {
                    let user = res.data[i]
                    users.push({
                        cliente: user.client.name + ' ' + user.client.lastname,
                        lote: user.lote,
                        mensura: user.mensura,
                        cuotasVencidas: user.cuotasVencidas,
                        saldoALaFecha: user.saldoALaFecha,
                        saldoTotal: user.saldoTotal,
                        pagoTotal: user.pagoTotal,
                        firstPaymentDate: user.firstPaymentDate ? user.firstPaymentDate.split("T")[0].split("-")[2] + "-" + user.firstPaymentDate.split("T")[0].split("-")[1] + "-" + user.firstPaymentDate.split("T")[0].split("-")[0] : "",
                        estado: user.status ? user.status : "Actualizando",
                        _id: user._id,
                    })
                }
                setData(users)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    const [columns] = React.useState([
        { name: 'cliente', title: 'Cliente' },
        { name: 'lote', title: 'Lote' },
        { name: 'mensura', title: 'Mensura' },
        { name: 'estado', title: 'Estado' },
        { name: 'cuotasVencidas', title: 'Cuotas vencidas' },
        { name: 'saldoALaFecha', title: 'Saldo deudor' },
        { name: 'saldoTotal', title: 'Saldo total' },
        { name: 'pagoTotal', title: 'Pago total ' },
        { name: 'firstPaymentDate', title: '1era fecha de vencimiento' },
    ]);

    const [tableColumnExtensions] = useState([
        { columnName: 'cliente', width: 250 },
        { columnName: 'lote', width: 250 },
        { columnName: 'mensura', width: 250 },
        { columnName: 'estado', width: 250 },
        { columnName: 'cuotasVencidas', width: 250 },
        { columnName: 'saldoALaFecha', width: 250 },
        { columnName: 'saldoTotal', width: 250 },
        { columnName: 'pagoTotal', width: 150 },
        { columnName: 'firstPaymentDate', width: 320 },
    ]);

    const HighlightedCell = ({ value, style, ...restProps }) => (
        <Table.Cell {...restProps}>
            <span style={{ color: value === "Vencido" ? '#DD3434' : value === "Al día" ? "#039487" : "#FFA500" }}>
                {value}
            </span>
        </Table.Cell>
    );

    const Cell = (props) => {
        const { column } = props;
        if (column.name === 'estado') {
            return <HighlightedCell {...props} />;
        }
        return <Table.Cell {...props} />;
    };

    const TableRow = ({ row, ...restProps }) => (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            onClick={() => navigate(`/dashboard/clientes/detalles/?id=${row._id}`)}
            style={{
                cursor: 'pointer',
            }}
        />
    );

    const onSave = (workbook) => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
        });
    };


    return (
        <div className="card">
            <Grid rows={data} columns={columns} getRowId={getRowId} >
                <SearchState />
                <IntegratedFiltering />
                <Table />
                <VirtualTable height={600} cellComponent={Cell} rowComponent={TableRow} />
                <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
                <TableHeaderRow />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <SearchPanel />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={data}
                columns={columns}
                onSave={onSave}
            />
        </div>
    );
}

export default Clients;